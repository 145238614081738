<template>
  <div class="price-details">
    <h4>{{ $t(title || "Detalls del preu", locale) }}</h4>
    <b-list-group flush :class="{ 'mb-1': link }">
      <!-- ACCOMMODATION PRICE -->
      <b-list-group-item class="d-flex justify-content-between border-0 p-0">
        <span>{{ accommodationPriceText }}</span>
        <span>{{ formatCurrency(subtotal) }}</span>
      </b-list-group-item>

      <!-- CHARGABLE SERVICES -->
      <b-list-group-item
        v-for="bookingService in chargableServices"
        :key="bookingService.uuid"
        class="d-flex justify-content-between border-0 p-0"
      >
        <span>{{ serviceNameWithUnitPrice(bookingService) }}</span>
        <span>{{ bookingServicePriceText(bookingService) }}</span>
      </b-list-group-item>

      <!-- TOTAL -->
      <b-list-group-item
        class="d-flex justify-content-between font-weight-bolder px-0"
      >
        <span>{{ $t("Total", locale) }}</span>
        <span>{{ formatCurrency(total) }}</span>
      </b-list-group-item>
    </b-list-group>

    <a v-if="link" class="text-primary" :href="link" target="blank">
      {{ $t("emails.common.priceMoreDetails", locale) }}
    </a>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem } from "bootstrap-vue";
import { formatCurrency } from "@/utils/formatters";
import {
  getBookingServiceClientTotalPriceText,
  getBookingServiceName,
  getBookingServiceNameWithClientUnitPrice,
  getNightsText,
} from "@/utils/methods";

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: null,
    },
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    averageRatePerNight() {
      return this.$store.getters["booking/averageRatePerNight"];
    },
    accommodationPriceText() {
      if (!this.averageRatePerNight || !this.nightsText) {
        return this.$t("Allotjament", this.locale);
      }

      return `${formatCurrency(this.averageRatePerNight)} x ${this.nightsText}`;
    },
    chargableServices() {
      return this.$store.getters["booking/chargableServices"];
    },
    nightsText() {
      return getNightsText(this.booking.nights, this.locale);
    },
    subtotal() {
      return this.$store.getters["booking/subtotalDiscounted"];
    },
    total() {
      return this.$store.getters["booking/total"];
    },
  },
  methods: {
    formatCurrency(price) {
      return formatCurrency(price);
    },
    bookingServicePriceText(bookingService) {
      return getBookingServiceClientTotalPriceText(
        bookingService,
        this.booking.nights,
        this.locale
      );
    },
    serviceNameWithUnitPrice(bookingService) {
      if (bookingService?.paymentFrequency !== "DAY") {
        return getBookingServiceName(bookingService, this.locale);
      }

      return getBookingServiceNameWithClientUnitPrice(
        bookingService,
        this.locale
      );
    },
  },
};
</script>
