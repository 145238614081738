<template>
  <b-row no-gutters>
    <b-col cols="12" class="border-bottom">
      <h5 class="font-weight-bold p-2 mb-0 text-center bg-light">
        {{ subject }}
      </h5>
    </b-col>
    <b-col cols="12" class="p-2">
      <!-- INTRO -->
      <intro
        class="mb-2"
        :name="client.firstName"
        :text="$t('emails.clientPrebookingConfirmation.intro', locale)"
      />

      <!-- ALERT -->
      <alert
        class="mb-2"
        variant="warning"
        :text="$t('emails.clientPrebookingConfirmation.alert', locale)"
      />

      <!-- PICTURE && ACCOMMODATION INFO -->
      <accommodation-info
        :accommodation="accommodation"
        booking-status="REQUESTED"
        :locale="locale"
      />

      <b-divider size="lg" />

      <!-- CHECK-IN AND CHECKOUT TIMES -->
      <checkin-checkout
        :checkin="checkin"
        :checkout="checkout"
        :locale="locale"
      />

      <b-divider size="lg" />

      <!-- PRICE DETAILS -->
      <client-price-details :locale="locale" />

      <b-divider size="lg" />

      <!-- PAYMENTS -->
      <div>
        <h4 class="mb-2">
          {{ $t("Pagaments", locale) }}
        </h4>

        <!-- PENDING PAYMENTS -->
        <div>
          <b-list-group flush class="my-2">
            <!-- INITIAL PAYMENT AMOUNT -->
            <b-list-group-item
              class="d-flex justify-content-between align-items-center border-0 px-0"
            >
              <div>
                <div class="font-weight-bold">
                  {{ $t("Pagament inicial", locale) }}
                </div>
                <small>
                  {{ formatCurrency(initialPaymentAmount) }}
                </small>
              </div>
              <b-badge pill variant="warning">
                {{ $t("emails.common.waitingPayment", locale) }}
              </b-badge>
            </b-list-group-item>
            <!-- PAY WITH CREDIT CARD -->
            <b-list-group-item class="border-0 px-0">
              <div class="font-weight-bold mb-1">
                {{ $t("emails.common.creditCardPayment", locale) }}
              </div>
              <b-button block variant="primary" :href="tpvLink" target="_blank">
                {{ $t("Paga ara", locale) }}
              </b-button>
            </b-list-group-item>
            <!-- PAY WITH BANK TRANSFER -->
            <b-list-group-item class="border-0 px-0">
              <div class="font-weight-bold mb-1">
                {{ $t("emails.common.bankTransferPayment", locale) }}
              </div>
              <b-alert class="mb-0" variant="secondary" :show="true">
                <div class="alert-body">
                  <p>
                    {{ $t("Banc", locale) }}: <b>{{ agencyBankName }}</b>
                  </p>
                  <p>
                    IBAN: <b>{{ agencyIban }}</b>
                  </p>
                  <p>
                    SWIFT/BIC: <b>{{ agencySwift }}</b>
                  </p>
                  <p>
                    {{ $t("Beneficiari", locale) }}:
                    <b>{{ agencyLegalName }}</b>
                  </p>
                  <p>
                    {{ $t("Import", locale) }}:
                    <b>{{ formatCurrency(initialPaymentAmount) }}</b>
                  </p>
                  <p>
                    {{ $t("Concepte", locale) }}:
                    <b>{{ bankTransferSubject }}</b>
                  </p>
                  <p class="mt-1">
                    {{ $t("emails.common.bankTransferReceipt", locale) }}
                  </p>
                </div>
              </b-alert>
            </b-list-group-item>
          </b-list-group>
        </div>

        <!-- UPCOMING PAYMENTS -->
        <div v-if="finalPaymentAmount > 0 || onsitePayments.length">
          <b-divider dashed size="md" />
          <h5>
            <u>{{ $t("Propers pagaments", locale) }}</u>
          </h5>
          <b-list-group flush class="mt-2">
            <!-- FINAL PAYMENT -->
            <b-list-group-item
              v-if="finalPaymentAmount > 0"
              class="d-flex justify-content-between align-items-center border-0 px-0"
            >
              <div>
                <div class="font-weight-bold">
                  {{ $t("Pagament final", locale) }}
                </div>
                <small>
                  {{ formatCurrency(finalPaymentAmount) }} ·
                  {{ formatDateObjectToDate(finalPaymentDueDate) }}
                </small>
              </div>
              <b-badge pill variant="info">
                {{ $t("Programat", locale) }}
              </b-badge>
            </b-list-group-item>

            <!-- ONSITE PAYMENTS -->
            <b-list-group-item
              v-if="onsitePayments.length"
              class="d-flex justify-content-between align-items-center border-0 px-0"
            >
              <div>
                <div class="font-weight-bold">
                  {{ $t("emails.common.onsitePayments", locale) }}
                </div>
                <div
                  v-for="(onsitePayment, index) in onsitePayments"
                  :key="`onsite-payment-${index}`"
                >
                  <small>
                    {{ onsitePayment.name }}:
                    <span v-if="onsitePayment.price" class="ml-25">
                      {{ onsitePayment.price }}
                    </span>
                  </small>
                </div>
              </div>
              <b-badge pill variant="info">
                {{ $t("Programat", locale) }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>

      <b-divider size="lg" />

      <!-- GUESTS -->
      <guests
        :alert-text="
          $t('emails.clientPrebookingConfirmation.guestsAlert', locale)
        "
        :locale="locale"
      />

      <b-divider size="lg" />

      <!-- SECURITY DEPOSIT -->
      <template v-if="hasSecurityDeposit">
        <security-deposit
          :alert-text="
            $t('emails.common.clientSecurityDepositInfoAlert', locale)
          "
          :locale="locale"
        />

        <b-divider size="lg" />
      </template>

      <!-- BOOKING CONDITIONS -->
      <booking-policies :locale="locale" @policy-updated="onPolicyUpdated" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BListGroup,
  BListGroupItem,
  BButton,
  BAlert,
} from "bootstrap-vue";
import {
  getAccommodationTypeName,
  getGuestsText,
  getNightsText,
  getPaymentFrequencyUnit,
  getServiceName,
  getTpvLink,
} from "@/utils/methods";
import {
  formatCurrency,
  formatDateObjectToDate,
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import Intro from "@/views/bookings/booking/components/mail/Intro.vue";
import Alert from "@/views/bookings/booking/components/mail/Alert.vue";
import AccommodationInfo from "@/views/bookings/booking/components/mail/AccommodationInfo.vue";
import CheckinCheckout from "@/views/bookings/booking/components/mail/CheckinCheckout.vue";
import ClientPriceDetails from "@/views/bookings/booking/components/mail/ClientPriceDetails.vue";
import Guests from "@/views/bookings/booking/components/mail/Guests.vue";
import SecurityDeposit from "@/views/bookings/booking/components/mail/SecurityDeposit.vue";
import BookingPolicies from "@/views/bookings/booking/components/mail/BookingPolicies.vue";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BListGroup,
    BListGroupItem,
    BButton,
    BAlert,
    BDivider,
    Intro,
    Alert,
    AccommodationInfo,
    CheckinCheckout,
    ClientPriceDetails,
    Guests,
    SecurityDeposit,
    BookingPolicies,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
  },
  data() {
    return {
      content: {},
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationPicture() {
      return this.accommodation?.coverPicture || null;
    },
    accommodationType() {
      if (!this.accommodation) return null;
      return getAccommodationTypeName(this.accommodation.type, this.locale);
    },
    accommodationLocation() {
      if (!this.accommodation?.location) return null;
      const location = [];
      if (this.accommodation.location.city)
        location.push(this.accommodation.location.city);
      if (this.accommodation.location.region)
        location.push(this.accommodation.location.region);
      if (!location.length) return null;
      return location.join(", ");
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    checkin() {
      const formatting = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return formatDateStringToDate(
        this.booking.checkin,
        this.locale,
        formatting
      );
    },
    checkout() {
      const formatting = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return formatDateStringToDate(
        this.booking.checkout,
        this.locale,
        formatting
      );
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    averageRatePerNight() {
      return this.$store.getters["booking/averageRatePerNight"];
    },
    nightsText() {
      return getNightsText(this.booking.nights, this.locale);
    },
    subtotal() {
      return this.$store.getters["booking/subtotalDiscounted"];
    },
    total() {
      return this.$store.getters["booking/total"];
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    securityDepositPrice() {
      return this.$store.getters["booking/securityDepositPrice"];
    },
    chargableServices() {
      return this.$store.getters["booking/chargableServices"];
    },
    priceDetailsServices() {
      if (!this.chargableServices.length) return [];
      return this.chargableServices.map((bookingService) => {
        let serviceName = getServiceName(bookingService.service, this.locale);
        if (this.bookingServiceUnitPriceText(bookingService)) {
          serviceName += ` (${this.bookingServiceUnitPriceText(
            bookingService
          )})`;
        }

        let servicePrice = bookingService.pvpPrice || null;
        if (bookingService.paymentFrequency === "DAY") {
          servicePrice = bookingService.pvpPrice * this.booking.nights;
        }

        return {
          name: serviceName,
          price: servicePrice,
        };
      });
    },
    unchargableServices() {
      return this.$store.getters["booking/unchargableServices"];
    },
    initialPaymentAmount() {
      return this.$store.getters["booking/initialPaymentAmount"];
    },
    finalPaymentAmount() {
      return this.$store.getters["booking/finalPaymentAmount"];
    },
    finalPaymentDueDate() {
      return this.$store.getters["booking/finalPaymentDueDate"];
    },
    onsitePayments() {
      if (!this.unchargableServices.length) return [];
      return this.unchargableServices.map((bookingService) => ({
        name: getServiceName(bookingService.service, this.locale),
        price: this.bookingServiceUnitPriceText(bookingService),
        unit: this.paymentFrequencyUnit(bookingService.paymentFrequency) || "",
      }));
    },
    contentOnsitePayments() {
      if (!this.unchargableServices.length) return [];
      return this.unchargableServices.map((bookingService) => ({
        name: getServiceName(bookingService.service, this.locale),
        price: bookingService.pvpPrice,
        unit: this.paymentFrequencyUnit(bookingService.paymentFrequency) || "",
      }));
    },
    subject() {
      return `${this.$t(
        "emails.clientPrebookingConfirmation.subject",
        this.locale,
        {
          localizator: this.booking?.localizator || null,
          accommodationName: this.accommodation?.name || null,
        }
      )}`;
    },
    bankTransferSubject() {
      const text = [];
      if (this.client?.fullName) text.push(this.client.fullName);
      if (this.booking?.localizator) text.push(this.booking.localizator);
      return text.join(" ");
    },
    agencyBankName() {
      return this.$store.getters["agencyInfo/bankName"];
    },
    agencyIban() {
      return this.$store.getters["agencyInfo/iban"];
    },
    agencySwift() {
      return this.$store.getters["agencyInfo/swift"];
    },
    agencyLegalName() {
      return this.$store.getters["agencyInfo/legalName"];
    },
    isLastMinute() {
      return this.$store.getters["booking/isLastMinute"];
    },
    bookingGuests() {
      return (
        getGuestsText(
          {
            adults: this.booking?.adults,
            children: this.booking?.children,
            babies: this.booking?.babies,
          },
          this.locale
        ) || this.$t("Pendent")
      );
    },
    tpvLink() {
      return getTpvLink({
        locale: this.locale,
        accommodation: this.accommodation?.name || null,
        localizator: this.booking?.localizator || null,
        checkin: formatDateStringToDatabaseDate(this.booking.checkin),
        checkout: formatDateStringToDatabaseDate(this.booking.checkout),
        amount: (this.initialPaymentAmount / 100).toFixed(2),
        name: this.client?.fullName || null,
        comment: `${this.$t("Pagament inicial", this.locale)}+${
          this.booking.localizator
        }`,
      });
    },
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.$emit("content-updated", this.content);
      },
    },
  },
  created() {
    this.initContent();
  },
  methods: {
    initContent() {
      this.content = {
        locale: this.locale,
        subject: this.subject,
        clientName: this.client?.firstName || null,
        clientEmail: this.client?.email || null,
        introHello: `Hola ${this.client?.firstName || null}`,
        introText: this.$t(
          "emails.clientPrebookingConfirmation.intro",
          this.locale
        ),
        introAlertText: this.$t(
          "emails.clientPrebookingConfirmation.alert",
          this.locale
        ),
        guestsAlertText: this.$t(
          "emails.clientPrebookingConfirmation.guestsAlert",
          this.locale
        ),
        securityDespositAlertText: this.$t(
          "emails.common.clientSecurityDepositInfoAlert",
          this.locale
        ),
        accommodationPicture: this.accommodationPicture?.url || null,
        accommodationDescription: this.accommodationType,
        accommodationName: this.accommodation?.name || null,
        accommodationLocation: this.accommodationLocation,
        checkin: this.checkin,
        checkout: this.checkout,
        averageRatePerNight: this.averageRatePerNight,
        nights: this.nightsText,
        subtotal: this.subtotal,
        priceDetailsServices: this.priceDetailsServices,
        total: this.total,
        initialPaymentAmount: this.initialPaymentAmount,
        bankName: this.agencyBankName,
        bankIban: this.agencyIban,
        bankSwift: this.agencySwift,
        bankBeneficiary: this.agencyLegalName,
        bankTransferSubject: this.bankTransferSubject,
        bankTransferReceiptText: this.$t(
          "emails.common.bankTransferReceipt",
          this.locale
        ),
        finalPaymentAmount: this.finalPaymentAmount,
        finalPaymentDueDate: formatDateObjectToDate(this.finalPaymentDueDate),
        onsitePayments: this.contentOnsitePayments,
        guests: this.bookingGuests,
        securityDeposit: this.securityDepositPrice,
        policies: null,
        paymentLink: this.tpvLink,
      };
    },
    onPolicyUpdated(policy) {
      this.content.policies = policy;
    },
    formatDateObjectToDate(date) {
      return formatDateObjectToDate(date);
    },
    formatCurrency(price) {
      return formatCurrency(price);
    },
    paymentFrequencyUnit(code) {
      return getPaymentFrequencyUnit(code, this.locale);
    },
    bookingServiceUnitPriceText(bookingService) {
      if (!bookingService || !bookingService?.paymentFrequency) return null;
      if (
        bookingService.paymentFrequency === "ONCE" &&
        bookingService.chargable
      )
        return null;
      return `${this.formatCurrency(bookingService.pvpPrice)}${
        this.paymentFrequencyUnit(bookingService.paymentFrequency) || ""
      }`;
    },
  },
};
</script>
