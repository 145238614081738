<template>
  <div v-if="policy">
    <h4 class="mb-2">
      {{ $t(title || "Polítiques de reserva", locale) }}
    </h4>
    <booking-policy-preview
      :policy="policy"
      :locale="locale"
      @policy-updated="onPolicyUpdated"
    />
  </div>
</template>

<script>
import BookingPolicyPreview from "@/views/booking-policies/list/components/BookingPolicyPreview.vue";

export default {
  components: {
    BookingPolicyPreview,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    policy() {
      return this.booking?.policy || null;
    },
  },
  methods: {
    onPolicyUpdated(policy) {
      this.$emit("policy-updated", policy);
    },
  },
};
</script>
